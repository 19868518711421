import { Controller } from "@hotwired/stimulus"
import 'https://web.squarecdn.com/v1/square.js'
import { post } from '@rails/request.js'

export default class extends Controller {

  static values = {
    application: String,
    location: String,
    invoice: Number
  }

  async connect() {
    const payments = Square.payments(this.applicationValue, this.locationValue);
    const card = await payments.card();
    await card.attach('#card-container');

    const cardButton = document.getElementById('card-button');
    cardButton.addEventListener('click', async () => {
      const statusContainer = document.getElementById('payment-status-container');
      const errorContainer = document.getElementById('payment-error-container');

      try {
        const result = await card.tokenize();
        if (result.status === 'OK') {
          const response = await this.sendPayment(result.token);
          if (response.ok){
            statusContainer.innerHTML = "Payment Successful";
            location.reload();
          }else{
            statusContainer.innerHTML = "Payment Failed";
            const body = await response.json
            const code = body.errors.code
            const detail = body.errors.detail
            const category = body.errors.category
            errorContainer.innerHTML =  `<div class="grid card p-5">
            <h4 class="text-danger">${detail}</h4>
            <h4>${code}</h4>
            <h6>${category}</h6>
            </div>`
          }
        } else {
          let errorMessage = `Tokenization failed with status: ${result.status}`;
          if (result.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
              result.errors
            )}`;
          }

          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e);
        statusContainer.innerHTML = "Payment Failed";
      }
    });
  }

  async sendPayment(source_id){
    const response = post(`/invoices/${this.invoiceValue}/complete`, {
      body: {
        source_id: source_id
      },
      contentType: "application/json",
      headers: {},
      query: {},
      responseKind: "application/json"
    })
    return response
  }

}
